import React from 'react';
import {FormInstance} from "antd";


interface State {
}

interface Props {
}

class HomeView extends React.Component<Props, State> {

    //Examples: https://github.com/reactchartjs/react-chartjs-2/tree/master/example/src/charts
    private formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {
            results: []
        }
    }

    render() {

        return <>
            <h1>Hello there </h1>
        </>
    }

}

export default HomeView;