import React from 'react';
import {Meme} from "../../data/Meme";
import {MemeApi} from "../../apis/MemeApi";
import Config from "../../frame/Config";
import Search from "antd/es/input/Search";
import {Card, Col, Image, List, message, Modal, Row, Select} from "antd";
import {LinkOutlined, LoadingOutlined, PlayCircleOutlined, ShareAltOutlined} from "@ant-design/icons";
import {Option} from "antd/es/mentions";
import InfiniteScroll from "react-infinite-scroll-component";

interface State {
    results: Meme[],
    loading: boolean,
    videoModalId?: string,
    videoTitle?: string,
    hasMore: boolean
}

interface Props {
}

class QueryMemes extends React.Component<Props, State> {

    private static PAGE_SIZE = 25
    private query: string = "";
    private currentPage = 0;

    constructor(props: Props) {
        super(props);
        this.state = {
            results: [],
            loading: false,
            hasMore: true
        }
    }

    componentDidMount() {
        this.search(this.query, 0);
    }

    search(query: string, page: number) {
        this.query = query;
        this.currentPage = page;
        this.setState({loading: true})
        MemeApi.query(this.query, page, QueryMemes.PAGE_SIZE)
            .then(value => {
                if (page === 0) {
                    this.setState({results: value, loading: false})
                } else {
                    let accumulatedResults = this.state.results;
                    accumulatedResults.push(...value);
                    this.setState({results: accumulatedResults, loading: false})
                }

                this.setState({hasMore: (this.state.results.length > 0 && this.state.results.length % QueryMemes.PAGE_SIZE) === 0})

            });
    }

    render() {

        return <>

            {
                this.state.videoModalId ?
                    <Modal
                        title={this.state.videoTitle}
                        visible
                        footer={null}
                        onCancel={e => this.setState({videoModalId: undefined, videoTitle: undefined})}
                        destroyOnClose
                        width="50%"
                    >
                        <video
                            controls
                            autoPlay
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                display: "block"
                            }}
                        >
                            <source src={Config.getBaseURL() + "/file/" + this.state.videoModalId}/>
                        </video>
                    </Modal> : null
            }


            <div className="sticky">
                <h1>Memes</h1>

                <Search loading={this.state.loading}
                        onSearch={value => this.search(value, 0)}
                        enterButton
                        placeholder="Suche nach Memes"
                        style={{marginBottom: "16px"}}
                />
            </div>


            <InfiniteScroll
                dataLength={this.state.results.length} //This is important field to render the next data
                next={() => this.search(this.query, ++this.currentPage)}
                hasMore={this.state.hasMore}
                loader={<LoadingOutlined style={{fontSize: "50px", textAlign: 'center', width: "100%"}}/>}
                endMessage={<h3 style={{textAlign: "center", width: "100%"}}>Gebd nix meh!</h3>}
                style={{overflow: "hidden"}}
                scrollableTarget="contentContainer"
            >
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 4,
                        lg: 4,
                        xl: 4,
                        xxl: 4,
                    }}
                    dataSource={this.state.results}
                    style={{padding: "1px"}}
                    renderItem={item => (
                        <List.Item>
                            {item.videoMediaId ? this.buildVideo(item) : this.buildImage(item)}
                        </List.Item>
                    )}
                />

            </InfiniteScroll>

        </>
    }

    private buildImage(item: Meme) {
        return <Card
            cover={<Image src={Config.getBaseURL() + "/file/" + item.mediaId}/>}
            actions={[
                <ShareAltOutlined
                    onClick={() => {
                        navigator.clipboard.writeText(Config.getBaseURL() + "/file/" + item.mediaId);
                        message.info("Kopiert.");
                    }}
                />
            ]}
        >
            <Card.Meta title={item.designation} description={item.description}/>
        </Card>
    }

    private buildVideo(item: Meme) {
        return <Card
            cover={<Image
                src={Config.getBaseURL() + "/file/" + item.mediaId}
                preview={{
                    mask: (<PlayCircleOutlined style={{fontSize: "3em"}}/>),
                    visible: false
                }}
                onClick={() => this.setState({videoModalId: item.videoMediaId, videoTitle: item.designation})}
            />}
            actions={[
                <ShareAltOutlined
                    onClick={() => {
                        navigator.clipboard.writeText(Config.getBaseURL() + "/file/" + item.videoMediaId);
                        message.info("Kopiert.");
                    }}
                />
            ]}
        >
            <Card.Meta title={item.designation} description={item.description}/>
        </Card>
    }
}

export default QueryMemes;