import {LoginResponse} from "./LoginResponse";
import {OnFailCallback, SecurityCallback} from "./Callbacks";
import Rest from "../apis/Rest";
import Config from "../frame/Config";

//TODO: refactor...
class Security {

    private static instance: Security | null = null;
    private callBacks: SecurityCallback[] = [];

    private constructor() {
        this._loggedIn = localStorage.getItem("loggedIn") === "true";
        this._token = localStorage.getItem("token");
        this._username = localStorage.getItem("username");

    }

    private _loggedIn: boolean;

    get loggedIn(): boolean {
        return this._loggedIn;
    }

    private _token: string | null;

    get token(): string {
        return this._token == null ? "" : this._token;
    }

    private _username: string | null;

    get username(): string {
        return this._username == null ? "" : this._username;
    }

    public static get(): Security {
        if (this.instance === null) {
            this.instance = new Security();
        }
        return this.instance;
    }

    private static handleFailure(response: Response, onFail: OnFailCallback) {
        console.log(response);
        let message: string | null = response.headers.get("errors");
        onFail(message, response.status);
    }

    public login(username: string, password: string, onFail: OnFailCallback) {
        const url = Config.getBaseURL() + "/auth";
        const body = {username: username, password: password};

        fetch(url, {method: 'POST', body: JSON.stringify(body)})
            .then(response => response.ok ?
                this.handleSuccess(response) :
                Security.handleFailure(response, onFail))
            .catch(reason => this.handleError(reason, onFail));
    }

    loginByGoogle(tokenId: string, onFail: OnFailCallback) {
        const url = Config.getBaseURL() + "/auth";
        const body = {googleAuthToken: tokenId};

        fetch(url, {method: 'POST', body: JSON.stringify(body)})
            .then(response => response.ok ?
                this.handleSuccess(response) :
                Security.handleFailure(response, onFail))
            .catch(reason => this.handleError(reason, onFail));
    }

    public logout() {

        Rest.delete("/auth").then(value => {
            this._token = null;
            this._loggedIn = false;
            this._username = null;
            localStorage.removeItem("token");
            localStorage.removeItem("username")
            localStorage.removeItem("loggedIn");
            this.callBacks.forEach(value => value(this._loggedIn));
        })
    }

    public registerCallback(callback: SecurityCallback) {
        this.callBacks.push(callback);
    }

    private handleError(reason: any, onFail: OnFailCallback) {
        console.log(reason);
        onFail("Verbindung konte nicht aufgebaut werden!", 401);
    }

    private handleSuccess(response: Response) {
        console.log(response.headers.get("errors"));
        response.json()
            .then(data => data as LoginResponse)
            .then(data => this.setState(data))
            .then(() => this.callBacks.forEach(value => value(true)));
    }

    private setState(data: LoginResponse) {
        this._token = data.token;
        this._loggedIn = true;
        this._username = data.username;
        localStorage.setItem("token", this.token);
        localStorage.setItem("username", this._username)
        localStorage.setItem("loggedIn", "true");
        window.location.href = "/";
    }
}

export default Security;