import React from 'react';
import './App.css';
import 'antd/dist/antd.less';
import {BrowserRouter, Link, Route, Switch} from 'react-router-dom';
import {
    HomeOutlined,
    MenuOutlined, PlusOutlined, SearchOutlined
} from '@ant-design/icons';
import {BackTop, Button, Col, Layout, Menu, Row} from "antd";
import {Content, Header} from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import LoginMenu from "./LoginMenu"
import AddMeme from "../pages/memes/AddMeme";
import NotFound from "../pages/NotFound";
import LocalSettingsView from "../pages/localsettings/LocalSettingsView";
import HomeView from "../pages/home/HomeView";
import QueryMemes from "../pages/memes/QueryMemes";

type Props = {}

type State = {
    drawerOpened: boolean,
    offline: boolean,
    drawSize: number
}

class App extends React.Component<Props, State> {

    //Um das ganze zu deplyoen kann https://create-react-app.dev/docs/deployment/ -> Nginx

    private routes = [
        {path: "/", key: "1"},
        {path: "/add-meme", key: "2"},
        {path: "/query-memes", key: "3"},
        {path: "/local-settings", key: "100"}
    ];

    constructor(props: Props) {
        super(props);
        this.state = {
            drawerOpened: true,
            offline: false,
            drawSize: window.innerHeight
        }
        window.onresize = () => this.setState({drawSize: window.innerHeight});
    }

    render() {

        return (

            <BrowserRouter>
                <Layout>
                    <Header className="app-bar green-background">
                        <Row>
                            <Col>
                                <Button className="spacedButton green-background" onClick={this.toggle}>
                                    <MenuOutlined color="white" className="white-font drawer-icon"/>
                                </Button>
                            </Col>

                            <Col flex="auto">
                                <h1 className="white-font">Meme-Archives</h1>
                            </Col>
                            <Col>
                                <LoginMenu/>
                            </Col>
                        </Row>
                    </Header>

                    <Layout>

                        <Sider theme="light" collapsible collapsed={this.state.drawerOpened} trigger={null}>
                            <Menu theme="light" mode="inline" selectable
                                  defaultSelectedKeys={[this.getSelectedKeyByLocation()]}>
                                <Menu.Item key={1} icon={<HomeOutlined/>}>
                                    <Link to={this.getPathByKey("1")}>Startseite</Link>
                                </Menu.Item>
                                <Menu.Item key={2} icon={<PlusOutlined/>}>
                                    <Link to={this.getPathByKey("2")}>Meme hinzufügen</Link>
                                </Menu.Item>
                                <Menu.Item key={3} icon={<SearchOutlined/>}>
                                    <Link to={this.getPathByKey("3")}>Memes suchen</Link>
                                </Menu.Item>
                            </Menu>
                            <p style={{width: "100%", textAlign: "center", marginTop: "25px"}}><Link
                                to={this.getPathByKey("100")}>1.0.1</Link></p>
                        </Sider>

                        <Content className="main-content"
                                 id="contentContainer"
                                 style={{
                                     height: window.innerHeight - 64,
                                     overflow: "auto"
                                 }}>
                            <Switch>
                                <Route exact path={this.getPathByKey("1")} component={HomeView}/>
                                <Route exact path={this.getPathByKey("2")} component={AddMeme}/>
                                <Route exact path={this.getPathByKey("3")} component={QueryMemes}/>
                                <Route exact path={this.getPathByKey("100")} component={LocalSettingsView}/>
                                <Route component={NotFound}/>
                            </Switch>
                        </Content>
                    </Layout>

                </Layout>
                <BackTop/>
            </BrowserRouter>
        );

    }

    toggle = () => {
        this.setState({
            drawerOpened: !this.state.drawerOpened,
        });
    };

    private getPathByKey(key: string): string {
        const res = this.routes.filter(value => value.key === key);

        if (res.length !== 1) {
            return "/";
        }
        return res[0].path;

    }

    private getSelectedKeyByLocation(): string {
        const currentPath = window.location.pathname;
        const res = this.routes.filter(value => value.path === currentPath);
        if (res.length !== 1) {
            return "0";
        }
        return res[0].key;
    }
}

export default App;
