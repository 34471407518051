import {message} from 'antd';
import Security from "../global/Security";
import {BaseResponse} from "../data/BaseResponse";
import EasyLogger from "../frame/EasyLogger";
import Config from "../frame/Config";

class Rest {



    public static get<Type extends BaseResponse | BaseResponse[]>(relativeUrl: string): Promise<Type> {
        const url = Config.getBaseURL() + relativeUrl;
        const now = Date.now();
        return fetch(url, {method: 'GET', headers: {'Authorization': Security.get().token}})
            .then(value => {
                EasyLogger.DEBUG(`GET Request against ${relativeUrl} took ${Date.now() - now} ms`)
                return value;
            })
            .then(response => Rest.handleGetResponse(response))
            .then(response => response.json())
            .then(data => data as Type);
    }

    public static handleGetResponse(response: Response): Response {
        if (response.status !== 200) {
            this.handleError(response);
        }
        return response;
    }

    public static put<Type extends BaseResponse>(value: Type, relativeUrl: string): Promise<Type> {
        return this.save<Type>('PUT', value, relativeUrl);
    }

    public static post<Type extends BaseResponse>(value: Type, relativeUrl: string): Promise<Type> {
        return this.save<Type>('POST', value, relativeUrl);
    }

    static handleSaveResponse(response: Response): Response {
        if (response.status !== 200) {
            this.handleError(response);
        } else {
            message.info("Speichern erfolgreich!");
        }
        return response;
    }

    public static delete(relativeUrl: string): Promise<any> {
        const url = Config.getBaseURL() + relativeUrl;
        return fetch(url, {method: 'DELETE', headers: {'Authorization': Security.get().token}})
            .then(response => Rest.handleDeleteResponse(response));
    }

    static handleDeleteResponse(response: Response): Response {
        if (response.status !== 200) {
            Rest.handleError(response);
        } else {
            message.info("Löschen erfolgreich!");
        }
        return response;
    }

    private static save<Type extends BaseResponse>(method: 'PUT' | 'POST', value: Type, relativeUrl: string): Promise<Type> {
        const url = Config.getBaseURL() + relativeUrl;
        return fetch(url, {
            method: method, body: JSON.stringify(value),
            headers: {'Authorization': Security.get().token}
        })
            .then(response => Rest.handleSaveResponse(response))
            .then(response => response.json())
            .then(data => data as Type);
    }

    private static handleError(response: Response) {
        const error: string = response.headers.get("error") || "";
        message.error(error);
        //TODO: throw new Error(error);
    }

}

export default Rest;