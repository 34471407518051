class Config {

    public static BASE_URL_DEFAULT = " http://localhost:8080/backend-1.0.1/rest/";

    public static getBaseURL(): string {
        let url: string | null = localStorage.getItem("api-url");

        if (url == null) {
            return this.BASE_URL_DEFAULT;
        }
        return url;
    }

    static showPreciousMetalOverview(): boolean {
        let url: string | null = localStorage.getItem("show-precious-metal-widget");
        return url === "true" || url === null;
    }
}

export default Config;