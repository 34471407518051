import React from 'react';
import {Button, Form, FormInstance, Image, Input, message, Upload, UploadProps} from "antd";
import {Meme} from "../../data/Meme";
import TextArea from "antd/es/input/TextArea";
import {MemeApi} from "../../apis/MemeApi";
import Config from "../../frame/Config";
import {UploadOutlined} from "@ant-design/icons";
import {UploadChangeParam} from "antd/es/upload";

interface State {
    results: Meme[],
    loading: boolean,
    uploadedFileId?: string
}

interface Props {
}

class AddMeme extends React.Component<Props, State> {


    private uploadProps: UploadProps = {
        name: 'file',
        action: Config.getBaseURL() + "file/simple",
        multiple: false,
        onChange: info => this.handleUploadSuccess(info),
        showUploadList: false,
        type: "drag"
    };


    //Examples: https://github.com/reactchartjs/react-chartjs-2/tree/master/example/src/charts
    private formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {
            results: [],
            loading: false
        }
    }

    render() {

        return <div style={{margin: "10px"}}>
            <h1>Add a Meme</h1>

            <Form
                layout="vertical"
                ref={this.formRef}
                name="basic"
                wrapperCol={{span: 12}}
            >
                <Form.Item
                    label="Meme URL"
                    name="originalUrl"
                    rules={[{required: this.state.uploadedFileId === undefined, message: 'Bitte URL ausfüllen!'}]}
                >
                    <Input autoFocus disabled={this.state.uploadedFileId !== undefined}/>
                </Form.Item>

                <Form.Item
                    label="Meme Upload"
                    name="file"
                >
                    <Upload {...this.uploadProps} style={{marginBottom: "10px"}}>
                        <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    label="Bezeichnung"
                    name="designation"
                    rules={[{required: true, message: 'Bitte URL ausfüllen!'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Beschreibung"
                    name="description"
                >
                    <TextArea/>
                </Form.Item>
                <Form.Item wrapperCol={{span: 12}}>
                    <Button type="primary" loading={this.state.loading}
                            onClick={() => this.createMeme()}>Hinzufügen</Button>

                </Form.Item>

            </Form>
            <br/>
        </div>
    }

    private createMeme() {
        this.setState({loading: true})
        this.formRef.current?.validateFields()
            .then((value: Meme) => value as Meme)
            .then(value => {
                if (this.state.uploadedFileId) {
                    value.mediaId = this.state.uploadedFileId;
                }
                MemeApi.post(value).then(() => {
                    this.setState({loading: false});
                });
            });
    }

    private handleUploadSuccess(info: UploadChangeParam) {

        if (info.file.status === "uploading") {
            this.setState({loading: true});
        }


        if (info.file.status === "done") {
            this.setState({uploadedFileId: info.file.response.id, loading: false});
            message.info("Upload erfolgreich!");
        }


    }
}

export default AddMeme;