import Rest from "./Rest";
import {Meme} from "../data/Meme";

export class MemeApi {

    public static query(query: string, page: number, pageSize: number): Promise<Meme[]> {
        return Rest.get(`/memes?query=${query}&page=${page}&page-size=${pageSize}`);
    }


    public static post(value: Meme): Promise<Meme> {
        return Rest.post(value, "/memes");
    }

}