import React from 'react';
import './App.css';
import {LogoutOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Dropdown, Form, Input, Menu, message, Modal} from "antd";
import Security from "../global/Security";
import AccountModal from "./AccountModal";


type Props = {}

type State = {
    loggedIn: boolean,
    loginDialogVisible: boolean,
    loginRequestOngoing: boolean,
    username: string,
    password: string,
    showAccountModal: boolean
}

class LoginMenu extends React.Component<Props, State> {

    state: State = {
        loggedIn: Security.get().loggedIn,
        loginDialogVisible: false,
        username: Security.get().username,
        password: "",
        loginRequestOngoing: false,
        showAccountModal: false
    }

    form: any;

    layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };

    tailLayout = {
        wrapperCol: {offset: 8, span: 16},
    };
    private subMenu: any = (
        <Menu>
            <Menu.Item key="2" onClick={() => this.showUserProfile()} icon={<UserOutlined/>}>
                Nutzerprofil
            </Menu.Item>
            <Menu.Item key="3" onClick={() => Security.get().logout()} icon={<LogoutOutlined/>}>
                Logout
            </Menu.Item>
        </Menu>
    );

    componentDidMount() {
        Security.get().registerCallback(loggedIn => {
            this.setState({loginRequestOngoing: false, loginDialogVisible: !loggedIn, loggedIn: loggedIn})
        });
    }

    render() {
        return (

            this.state.loggedIn ? <>
                    <Dropdown.Button className="spacedButton" overlay={this.subMenu} placement="bottomCenter"
                                     icon={<UserOutlined/>} onClick={() => this.showUserProfile()}>
                        {this.state.username}
                    </Dropdown.Button>
                    {this.state.showAccountModal ? <AccountModal parent={this}/> : null}
                </>
                :
                <div>
                    <Button className="spacedButton"
                            onClick={() => this.setState({loginDialogVisible: true})}>Login</Button>
                    {this.renderModal()}
                </div>
        );
    }

    login = () => {
        this.setState({loginRequestOngoing: true})
        Security.get().login(this.state.username, this.state.password, this.onFailedLogin);
    }

    onFailedLogin = (errorMessage: string | null, code: number) => {
        errorMessage = errorMessage === null ? "Fehler!" : errorMessage;
        message.error(errorMessage);
        this.setState({loginRequestOngoing: false});
    }

    private renderModal() {
        return (
            <Modal
                visible={this.state.loginDialogVisible}
                title="Login"
                footer={null}
                centered={true}
                maskClosable={false}
                closable={true}
                onCancel={e => this.setState({loginDialogVisible: false})}
            >

                <Form
                    {...(this.layout)}
                    name="basic"
                    initialValues={{remember: true}}
                    onFinish={this.login}
                >
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[{required: true, message: 'Username erforderlich!'}]}
                    >
                        <Input autoFocus
                               value={this.state.username}
                               onChange={event => this.setState({username: event.target.value})}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{required: true, message: 'Passwort erforderlich!'}]}
                    >
                        <Input.Password
                            value={this.state.password}
                            onChange={event => this.setState({password: event.target.value})}
                        />
                    </Form.Item>

                    <Form.Item {...(this.tailLayout)} >
                        <Button type="primary" htmlType="submit" loading={this.state.loginRequestOngoing}>
                            Login
                        </Button>
                    </Form.Item>
                </Form>

            </Modal>
        );
    }

    private showUserProfile() {
        this.setState({showAccountModal: true});
    }

}

export default LoginMenu;
