import {Account} from "../data/Account";
import Rest from "./Rest";

class AccountApi {

    public static get(): Promise<Account> {
        return Rest.get("/me/account");
    }

    public static put(value: Account): Promise<Account> {
        return Rest.put(value, "/me/account");
    }

    public static delete(): Promise<any> {
        return Rest.delete("/me/account");
    }

}

export default AccountApi;