import React from "react";
import {Link} from 'react-router-dom';

interface State {

}

interface Props {

}

class NotFound extends React.Component<Props, State> {

    render() {
        return <>
            <h1 style={{fontSize: "25px"}}>Ups! Du scheinst dich verlaufen zu haben.</h1>
            <p style={{fontSize: "20px"}}>Keine Sorge, wir finden schon deine Mami, klicke <Link
                to="/"><b>hier</b></Link> um zur Startseite zurückzukehren.</p>
        </>;
    }

}

export default NotFound;