import React from "react";
import {Checkbox, Input} from "antd";
import Comp from "../../global/Comp";

interface State {

}

interface Props {

}

class LocalSettingsView extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {

    }

    render() {
        return <>

            <h1>Lokale Konfiguration</h1>
            <p><i>Hinweis: Alle Daten werden sofort gespeichert.</i></p>

            <h5>Alternative Api-URL</h5>
            <Input defaultValue={this.get("api-url")} onChange={event => this.persist("api-url", event.target.value)}/>

        </>;
    }

    get(key: string): string {
        const res = localStorage.getItem(key);
        // @ts-ignore
        return Comp.nill(res) ? "" : res;
    }

    persist(key: string, value: string) {
        localStorage.setItem(key, value)
    }


}

export default LocalSettingsView;